@use '../../styles/yr' as *;

// OVERRIDE: We do not want padding inside the dialog
.hourly-weather-dialog__table > div > .fluid-table,
.hourly-weather-dialog__table > div > .fluid-table .fluid-table__table {
  padding: 0;
  margin: 0;
}

.hourly-weather-dialog__celestial_events_container {
  display: flex;
  justify-content: center;
}

.hourly-weather-dialog__celestial_events-link-sun-card {
  margin-left: size(2);
}
